import { gql } from "@apollo/client";

const GET_STOCK = gql`
  query {
    stock {
      clones {
        model
        name
        platform
        descriptions
        stock
        price
        iconUrl
        specs {
          key
          value
        }
      }
    }
  }
`;

const GET_SERVICE = gql`
  query {
    stock {
      services {
        code
        name
        description
        price
        input
      }
    }
  }
`;

const LOGIN = gql`
  mutation login($params: LoginParams!) {
    response(params: $params)
      @rest(
        type: "LoginResponse"
        path: "/auth/signin"
        method: "POST"
        bodyKey: "params"
      ) {
      data {
        accessToken
        expiresIn
      }
    }
  }
`;

const REGISTER = gql`
  mutation register($params: RegisterParams!) {
    response(params: $params)
      @rest(
        type: "RegisterResponse"
        path: "/auth/signup"
        method: "POST"
        bodyKey: "params"
      ) {
      data {
        accessToken
        expiresIn
      }
    }
  }
`;

const GET_ORDERS = gql`
  query {
    orders {
      id
      status
      type
      totalPrice
      requirements {
        ... on CloneOrderRequirement {
          quantity
          cloneModel
          cloneModelDetail {
            model
            name
            platform
            descriptions
            stock
            price
            iconUrl
          }
          fulfillment {
            platform
            model
            username
            password
            uuid
            email
            emailPassword
            emailRecovery
            emailRecoveryPassword
            twoFactorKey
            cookie
            token
            dob
            createdYear
            profileName
            friends
            groups
            followers
          }
        }
        ... on ServiceOrderRequirement {
          serviceCode
          quantity
          url
          serviceFulfillment: fulfillment
        }
      }
      createdAt
      updatedAt
      orderSecret
      receiverEmail
      downloadLinks {
        format
        url
        expiresAt
      }
    }
  }
`;

const GET_ORDER = gql`
  query GetOrder($orderId: Int!) {
    order(orderId: $orderId) {
      id
      status
      type
      totalPrice
      createdAt
      updatedAt
      orderSecret
      receiverEmail
      requirements {
        ... on CloneOrderRequirement {
          quantity
          cloneModel
          cloneModelDetail {
            model
            name
            platform
            descriptions
            stock
            price
            iconUrl
          }
          fulfillment {
            platform
            model
            username
            password
            uuid
            email
            emailPassword
            emailRecovery
            emailRecoveryPassword
            twoFactorKey
            cookie
            token
            dob
            createdYear
            profileName
            friends
            groups
            followers
          }
        }
        ... on ServiceOrderRequirement {
          serviceCode
          quantity
          url
          serviceFulfillment: fulfillment
        }
      }
      downloadLinks {
        format
        url
        expiresAt
      }
    }
  }
`;

const GET_GUEST_ORDERS = gql`
  query GetGuestOrders($orderSecrets: [String!]!) {
    getGuestOrders(orderSecrets: $orderSecrets) {
      id
      status
      type
      totalPrice
      createdAt
      updatedAt
      orderSecret
      receiverEmail
      requirements {
        ... on CloneOrderRequirement {
          quantity
          cloneModel
          cloneModelDetail {
            model
            name
            platform
            descriptions
            stock
            price
            iconUrl
          }
          fulfillment {
            platform
            model
            username
            password
            uuid
            email
            emailPassword
            emailRecovery
            emailRecoveryPassword
            twoFactorKey
            cookie
            token
            dob
            createdYear
            profileName
            friends
            groups
            followers
          }
        }
        ... on ServiceOrderRequirement {
          serviceCode
          quantity
          url
          serviceFulfillment: fulfillment
        }
      }
      downloadLinks {
        format
        url
        expiresAt
      }
    }
  }
`;

const CREATE_ORDER = gql`
  mutation CreateOrder($payload: CreateOrderInput!) {
    createOrder(payload: $payload) {
      id
      status
      type
      totalPrice
      createdAt
      updatedAt
      orderSecret
      receiverEmail
      requirements {
        ... on CloneOrderRequirement {
          quantity
          cloneModel
          cloneModelDetail {
            model
            name
            platform
            descriptions
            stock
            price
            iconUrl
          }
          fulfillment {
            platform
            model
            username
            password
            uuid
            email
            emailPassword
            emailRecovery
            emailRecoveryPassword
            twoFactorKey
            cookie
            token
            dob
            createdYear
            profileName
            friends
            groups
            followers
          }
        }
        ... on ServiceOrderRequirement {
          serviceCode
          quantity
          url
          serviceFulfillment: fulfillment
        }
      }
      downloadLinks {
        format
        url
        expiresAt
      }
    }
  }
`;

const CREATE_ORDER_SERVICE = gql`
  mutation CreateOrder($payload: CreateOrderInput!) {
    createOrder(payload: $payload) {
      id
      status
      type
      totalPrice
      createdAt
      updatedAt
      orderSecret
      receiverEmail
      requirements {
        ... on ServiceOrderRequirement {
          serviceCode
          quantity
          url
          fulfillment
        }
      }
    }
  }
`;

const CREATE_GUEST_ORDER = gql`
  mutation CreateGuestOrder(
    $receiverEmail: String!
    $payload: CreateOrderInput!
  ) {
    createGuestOrder(receiverEmail: $receiverEmail, payload: $payload) {
      id
      status
      type
      totalPrice
      createdAt
      updatedAt
      orderSecret
      receiverEmail
    }
  }
`;

const CREATE_PAYMENT_REQUEST_FOR_GUEST_ORDER = gql`
  mutation CreatePaymentRequestForGuestOrder($orderSecret: String!) {
    createPaymentRequestForGuestOrder(orderSecret: $orderSecret) {
      depositCode
      qrCode
    }
  }
`;

const CHECK_GUEST_ORDER_PAYMENT_STATUS = gql`
  query CheckGuestOrderPaymentStatus(
    $depositCode: String!
    $orderSecret: String!
  ) {
    checkGuestOrderPaymentStatus(
      depositCode: $depositCode
      orderSecret: $orderSecret
    ) {
      id
      status
      type
      totalPrice
      createdAt
      updatedAt
      orderSecret
      receiverEmail
    }
  }
`;

const LATEST_ORDERS_SUBSCRIPTION = gql`
  subscription OrderCreated {
    orderCreated {
      totalPrice
      createdAt
      receiverEmail
      requirements {
        quantity
        cloneModel
        cloneModelDetail {
          model
          name
          platform
          descriptions
          stock
          price
          iconUrl
        }
      }
    }
  }
`;

const GET_ME = gql`
  query getMe {
    response @rest(type: "GetMeResponse", path: "/users/me", method: "GET") {
      data {
        user {
          id
          username
          email
          phone
          password
          firstName
          lastName
          dateOfBirth
          photoUrl
          emailVerfied
          phoneVerfied
          createdAt
          googleId
          googleAccessToken
          googleRefreshToken
          facebookId
          facebookAccessToken
          facebookRefreshToken
          role
          wallet
        }
      }
    }
  }
`;

const GET_DEPOSIT_CODE = gql`
  query getDepositCode {
    response
      @rest(
        type: "GetDepositCodeResponse"
        path: "/payment/deposit-code"
        method: "GET"
      ) {
      data {
        depositCode
        qrCode
      }
    }
  }
`;

const CHECK_DEPOSIT = gql`
  mutation checkDeposit($params: CheckDepositParams!) {
    response(params: $params)
      @rest(
        type: "CheckDepositResponse"
        path: "/payment/deposit"
        method: "POST"
        bodyKey: "params"
      ) {
      data {
        transaction {
          amount
          type
          status
          depositCode
          paymentProvider
          note
          receiverWallet {
            id
          }
          forItem
          from
          id
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($params: ChangePasswordParams!) {
    response(params: $params)
      @rest(
        type: "ChangePasswordResponse"
        path: "/users/me/change-password"
        method: "POST"
        bodyKey: "params"
      ) {
      data
    }
  }
`;

const GET_PARSED_URL = gql`
  query Stock($serviceCode: BuffServiceEnum!, $url: String!) {
    parseUrl(serviceCode: $serviceCode, url: $url) {
      originalUrl
      cleanedUrl
      id
      name
      note
    }
  }
`;

export {
  GET_STOCK,
  LOGIN,
  REGISTER,
  GET_ORDERS,
  GET_ORDER,
  GET_GUEST_ORDERS,
  CREATE_ORDER,
  CREATE_GUEST_ORDER,
  CREATE_PAYMENT_REQUEST_FOR_GUEST_ORDER,
  CHECK_GUEST_ORDER_PAYMENT_STATUS,
  LATEST_ORDERS_SUBSCRIPTION,
  GET_ME,
  GET_DEPOSIT_CODE,
  CHECK_DEPOSIT,
  CHANGE_PASSWORD,
  GET_SERVICE,
  GET_PARSED_URL,
  CREATE_ORDER_SERVICE,
};
